import React from 'react';
import styles from './breeds.module.scss'
import { graphql } from 'gatsby';
import Layout from 'src/Theme/layout';
import SEO from 'src/components/seo';
import cs from 'classnames';
// import styles from 'src/pages/podcasty.module.sass';
import BigArticleTitle from 'src/components/shared/texts/BigArticleTitle/BigArticleTitle';
import BreedContentList from 'src/components/pages/podcasts/BreedContentList/BreedContentList';
import Article from "../../components/pages/article/Article/Article";


export const query = graphql`
    query breedsData($id: String!) {
        datoCmsDogBreed(breed: {eq: $id}) {
        breed
        id
        description {
                  ... on DatoCmsParagraph {
                paragraph
              }
              ... on DatoCmsTitle {
                titleNode {
                  id
                }
                title
              }
              ... on DatoCmsOrnamentTitle {
                ornamentTitle
              }
              ... on DatoCmsOrnamentParagraph {
                ornamentParagraph
              }
        }
        }
    }
`;

const Breed = ({data, pageContext}) => {
  const { id: breed } = pageContext
  return (
    <Layout>
      <SEO title={breed}/>
      <div className={cs(styles.podcastsWrap, 'firstSectionWrap')}>
        <BigArticleTitle className={styles.podcastsTitle}>{breed}</BigArticleTitle>
          <Article data={data.datoCmsDogBreed.description}/>
      </div>
    </Layout>
  );
}

export default Breed;
